import { FirebaseOptions } from "firebase/app";

export const USERSNAP_GLOBAL_API_KEY = "0c27c33e-7dcf-429a-9613-249a7ba43014";
export const FUNCTIONS_BASE_URL =
  "https://us-central1-mortgage-rec-engine-dev.cloudfunctions.net";
export const SPREADSHEET_IMAGE_FUNCTION_URL =
  "https://spreadsheet-to-image-service-oqnpzybqkq-uc.a.run.app/getPreviewImage";
export const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyArj04gRQBLXmGUUxvbfoIugTRmuYc76AI",
  authDomain: "mortgage-rec-engine-dev.firebaseapp.com",
  projectId: "mortgage-rec-engine-dev",
  storageBucket: "mortgage-rec-engine-dev.appspot.com",
  messagingSenderId: "653928143305",
  appId: "1:653928143305:web:9d2fc499cc5fe67c5eb018",
  measurementId: "G-PC6LQWP9JV",
};
export const APP_URL = "https://mre.test.blackfin.tools";
